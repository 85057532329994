import { BSU_ACCOUNT } from '@lib/constants';
import LocalStorage from '@lib/localStorage';
import { signIn, getUserInfo } from '@service/api';
import { setAccount, getAccount } from './sessionManager';

export function updateAccount(data: any) {
    const accountOld = getAccount() || {};
    const accountTemp = {...accountOld, ...data};
    const accountNew = {
        ...accountTemp,
        expires_at: accountTemp.expires_at || Number(new Date()) + (accountTemp?.expires_in || 0) * 1000
    }
    setAccount(accountNew);
}

export function isLogin() {
    const account = getAccount();
    const { access_token, expires_at } = account || {};
    const now = Number(new Date());
    return access_token && now < expires_at;
}

export function isExpires() {
    const account = getAccount();
    const { access_token, expires_at } = account || {};
    const now = Number(new Date());
    return access_token && now > expires_at;
}

export function refreshAccount() {
    return new Promise((resolve, reject) => {
        const account = getAccount();
        const { refresh_token } = account || {};
        if(!refresh_token) return reject();
        signIn({
            refresh_token: refresh_token,
            grant_type: 'refresh_token'
        }).then((res: any) => {
            setAccount(res);
            return getUserInfo(res?.access_token)
        }).then((res: any) => {
            updateAccount(res);
            resolve(getAccount());
        }).catch((err: any) => {
            const nowToken = getAccount()?.refresh_token;
            if(nowToken === refresh_token) {
                deleteAccount();
            }
            reject();
        })
    })
}

export function deleteAccount() {
    LocalStorage.remove(BSU_ACCOUNT);
}

export {
    setAccount,
    getAccount,
}