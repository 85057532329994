import { isBrowser } from "./utils";

export default {
    // 是否支持本地存储，如开启隐身模式
    _isLocalStorageSupported: (() => {
        if (!isBrowser) return false
        
        const _localStorage = window.localStorage;

        if (!_localStorage) {
            return false;
        }

        try {
            const testKey = 'byh5_test';
            _localStorage.setItem(testKey, 'wonderful');
            _localStorage.removeItem(testKey);
        } catch (error) {
            console.log(error);
            return false;
        }
        return true;
    })(),

    //设置缓存
    /**
    * @param key
    * @param value
    * @returns {boolean}
    */
    set(key: string, value: string) {
        if (this._isLocalStorageSupported) {
            try {
                window.localStorage.setItem(key, value);
                return;
            } catch (e) {
                console.log(e);
            }
        }
    },

    // 读取
    get(key: string) {
        if (this._isLocalStorageSupported) {
            const v = window.localStorage.getItem(key);
            return v === null ? undefined : v;
        }
        return undefined;
    },

    clearAll() {
        window.localStorage.clear();
    },

    // 删除
    remove(key: string) {
        if (this._isLocalStorageSupported) {
            window.localStorage.removeItem(key);
            return true;
        }
        return false;
    },
};

