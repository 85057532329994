import { isBrowser } from './utils'

class SessionStorage implements Storage {
  private constructor() {}

  private _storage = new Map<string, string>();

  get length() {
    return this._storage.size;
  }

  getItem(key: string): string | null {
    return this._storage.get(key) || null;
  }

  setItem(key: string, value: string): void {
    this._storage.set(key, value);
  }

  clear(): void {
    return this._storage.clear();
  }

  removeItem(key: string): void {
    this._storage.delete(key);
  }

  key(index: number): string | null {
    if(index < 0 || index >= this.length) {
      return null
    }
    const keys = this._storage.keys()
    for (let i = 0; i < this.length; i++) {
      const key = keys.next().value;
      if (index === i) {
        return key
      }
    }
    return null
  }

  private static _instance: null | SessionStorage = null;

  static getInstance(): Storage {
    if (!SessionStorage._instance) {
      SessionStorage._instance = new SessionStorage();
    }
    return SessionStorage._instance;
  }
}

const storage =
  isBrowser && window.sessionStorage
    ? window.sessionStorage
    : SessionStorage.getInstance();

export default storage;