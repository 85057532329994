import { isMobile } from '@lib/utils';
import { CancelTokenSource } from 'axios';
import dapperClient from './dapperClient';
import { isProd } from '@lib/constants';

// 类目页筛选discover
// todo search 接口应该用 params 的形式处理
export function categoryDiscover(q: string) {
    return dapperClient.post('/api/v3/discover', {
        q: q
    }, {
        headers: {
            "Content-Type": "application/x-protobuf-json"
        },
    });
}

export function getPageInfo(mode?: string) {
    if (mode) {
        return dapperClient.get(`/tapestry/api/v1/modes/${mode}`);
    }
    return dapperClient.get(`/tapestry/api/v1/modes`);
}

export function getMerchantList() {
    return dapperClient.get('/inventory/api/v1/merchants');
}

export function getCategory() {
    return dapperClient.get(`/inventory/api/v1/product-categories`)
}

export function productDiscover(q: string) {
    return dapperClient.post('/inventory/api/v3/discover', {
        q: q
    }, {
        headers: {
            "Content-Type": "application/json"
        },
    });
}

export function getBrandList(data: any) {
    return dapperClient.get(`/inventory/api/v1/brands`, {
        params: data
    })
}

export function getArticleList(data: any) {
    return dapperClient.get(`/article/api/v1/article`, {
        params: data
    })
}

export function getHotWords() {
    return dapperClient.get(`/inventory/api/v1/search/hot-words`)
}

export function judgeSelect(data: any, judgeCancel?: CancelTokenSource) {
    const options: any = {
        headers: {
            "Content-Type": "application/json",
        },
    }
    if (judgeCancel) {
        options.cancelToken = judgeCancel.token
    }
    return dapperClient.post(`/inventory/api/v1/search/condition`, {
        ...data,
    }, options)
}

// 注册
export function register(data: any) {
    return dapperClient.post(`/user/api/v1/signup`, data);
}

// 登录
export function signIn(data: any) {
    const params = new URLSearchParams();
    let authKey;
    if(isProd) {
        authKey = isMobile() ? 'ODIxMTI3MTU2MzUyNDIxZjhiMjUzZDUxMzdmNGJjZjM6JW9YKmVQMSFqaHRBJVExSjlRWTFLaw==' : 'MzA4Mzk5ZDg1MDU4NDNkZThiODcyOTNhY2MyMDQ3ZTc6UGZuUHFkaF5uQjlhMzQ3QG1mTnVAJg==';
    } else {
        authKey = 'YmV5b25kLXN0eWxlLW1vYmlsZS1oNToxMTExMQ==';
    }
    Object.keys(data)?.forEach(key => {
        params.append(key, data?.[key]);
    })
    return dapperClient.post(`/user/oauth/token`, params, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${authKey}`
        }
    })
}

// 账号激活
export function verification(data: any) {
    return dapperClient.post(`/user/api/v1/signup/verification`, data);
}

// 发送邮箱重置密码
export function sendResetPasswordRequest(email: string) {
    return dapperClient.put(`/user/api/v1/reset-password`, { email });
}

// 重置密码
export function resetPassword(data: any) {
    return dapperClient.post(`/user/api/v1/reset-password`, data);
}

// 获取用户信息
export function getUserInfo(token: any) {
    return dapperClient.get(`/user/api/v1/profile`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// 获取品牌图片
export function getBrandImg(brandId: string) {
    return dapperClient.get(`/inventory/api/v1/brand/${brandId}`)
}

// 获取相似商品
export function getSimilarProducts(productId: string) {
    return dapperClient.get(`/inventory/api/v1/search/image-search/product/${productId}?from=0&to=20`)
}

export interface SimilarResponse {
    products?: {
        searchProduct: {
            product: ProductDataType;
            merchantName: string;
            merchantOrigin: string;
        };
    }[];
    hasMore?: boolean;
    total: string;
}

export function getSimilarProductList(productId: string, query: {
    from: number
    to: number
}) {
    return dapperClient.get<SimilarResponse, SimilarResponse>(
        `/inventory/api/v1/search/image-search/product/${productId}`,
        {
            params: query,
        }
    );
}

export function postLove(pId: string) {
    return dapperClient.post(`/inventory/api/v1/favorite`, { type: 'PRODUCT', favoriteId: pId })
}

export function deleteLove(pId: string) {
    return dapperClient.delete(`/inventory/api/v1/favorite/${pId}`, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        }
    })
}

export interface CollectionResponse {
    list?: {
        product: ProductDataType;
        merchantName: string;
        merchantOrigin: string;
        activityOffs?: string[];
        numberViews?: string[];
        favoriteId: string;
    }[];
    total: number;
}

export function getCollectionProductList(
    query: {
        from: number;
        to: number;
        sort: string
        asc: boolean
    }
) {
    return dapperClient.get<CollectionResponse, CollectionResponse>(
        `/inventory/api/v1/favorite`,
        {
            params: query,
        }
    );
}

export const deleteCollectionProductList = (id: string) =>
    dapperClient.delete<{ count: number }, { count: number }>(
        `/inventory/api/v1/favorite/${id}`
    );

export const getSize = () => {
    return dapperClient.get(`/inventory/api/v1/sizes`)
}

export function getHomePageData(mode?: string) {
    if (mode) {
        return dapperClient.get(`/tapestry/api/v2/modes/${mode}`)
    }
    return dapperClient.get(`/tapestry/api/v2/modes`)
}

export function subscribeDiscount(email: string) {
    return dapperClient.post(
      "/inventory/api/v1/favorite/subscribe-discount",
      {
        type: "DISCOUNT",
        favoriteId: "ALL",
        email,
      }
    );
}

export function fetchPrice(data: any) {
    return dapperClient.post('/order/api/v1/shoppingcart/express-buy', data);
}

export function getArea(query?: string) {
    return dapperClient.get('/api/v1/basic-data/world-areas/US' + (query ? query : ''));
}

export function order(data: any) {
    return dapperClient.post('/order/api/v1/order', data);
}

export function fetchOrders() {
    return dapperClient.get('/order/api/v1/orders?f=0&t=500&d=true');
}

export function fetchOrderNum() {
    return dapperClient.get('/order/api/v1/orders/stat');
}


export interface PeerResponse {
  sameProducts: IPeerProduct[];
  similarProducts: IPeerProduct[];
  total: number
}

export function getPeerProducts(productId: string,  params?: Record<string, any>) {
    return dapperClient.get<PeerResponse, PeerResponse>(
      `/inventory/api/v1/standard-product/product/${productId}/with-recommend`,
      {
        params,
      }
    );
}

export const unSubscribe = (data: { email: string; token: string }) =>
  dapperClient.delete("/inventory/api/v1/favorite/unsubscribe-discount", {
    data,
  });

export function getLowestPriceTab() {
    return dapperClient.get('/inventory/api/v1/products/lowest-tabs');
}

export function getLowestPriceList(tab: string, option: any) {
    return dapperClient.get(`/inventory/api/v1/products/lowest-tab/${tab}`, {
        params: option
    });
}