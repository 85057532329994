import { BSU_ACCOUNT } from '@lib/constants';
import LocalStorage from '@lib/localStorage';

export function setAccount(account: any) {
  try {
    const accountNew = {
      ...account,
      expires_at: account.expires_at || Number(new Date()) + (account?.expires_in || 0) * 1000
    }
    LocalStorage.set(BSU_ACCOUNT, JSON.stringify(accountNew) || '{}');
  } catch (e) {}
}

export function getAccount() {
  try {
    return JSON.parse(LocalStorage.get(BSU_ACCOUNT) || '{}')
  } catch (e) {}
  return {}
}
