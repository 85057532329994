import React, { ReactElement, MouseEventHandler, useEffect } from 'react'
import './mask.scss'

interface IProps {
  visible: boolean
  close: MouseEventHandler<HTMLDivElement>
  children: ReactElement
  top?: string
  left?: string
  background?: string
}

const Mask = (props: IProps) => {
  useEffect(() => {
    if (props.visible) {
      document.body.style.height = '100vh'
      document.body.style.overflowY = 'hidden'
    }
    return () => {
      document.body.removeAttribute('style')
    }
  }, [props.visible])
  if (!props.visible) {
    return null
  }
  return (
    <div className='c-mask' style={{ top: props.top, left: props.left, background: props.background }}>
      <div className='c-mask-close' style={{ top: props.top }} onClick={props.close}></div>
      {props.children}
    </div>
  )
}

export default Mask