import React, { MouseEventHandler, useContext } from "react";
import Mask from '@modules/common/Mask';
import * as pms from './profile_m.module.scss';
import icClose from '@images/icon_close_whitebg.png';
import SearchBox from '@modules/category/searchBox';
import { getAccount, deleteAccount } from '@service/AccountService';
import { LayoutContext } from "@components/Context";

interface IProps {
  visible: boolean
  close: MouseEventHandler<HTMLElement>
}

const Profile = (props: IProps) => {
  const { collectionProductsLength, orderNum } = useContext<any>(LayoutContext);
  const account = getAccount();
  const { firstName, lastName, email } = account || {};

  function handleSignOut(e: any) {
    deleteAccount();
    props.close && props.close(e);
  }

  return (
    <Mask visible={props.visible} close={props.close}>
      <>
        <div className={`${pms.lMenuM} ${props.visible ? pms.lMenuMShow : ""}`}>
          <div className={pms.headerBg}>
            <div className={pms.headerM}>
              <div className={pms.headerMImg} onClick={props.close}></div>
              <div className={pms.headerTitle}>
                <div className={pms.logo}></div>
                <a href={"/"}>
                  <div>BEYOND STYLE</div>
                </a>
              </div>
              <div className={pms.headerMProfile}></div>
            </div>

            <SearchBox />
          </div>
          <div className={pms.menuContainer}>
            <dl>
              {orderNum > 0 ? <a href="/order" className={pms.order} onClick={props.close}>
                <dt>My orders</dt>
                <span>{orderNum}</span>
              </a> : null}
              <dt>B-LOVED List</dt>
              <dd>
                <a
                  href="/collection"
                  className={`${pms.arrow}`}
                  onClick={props.close}
                >
                  <span>B-LOVED Products</span>
                  <span className={`${pms.lovedCount}`}>
                    {collectionProductsLength} items
                  </span>
                </a>
              </dd>
              <dt>My Account</dt>
              <dd>{email}</dd>
              <dd>First Name: {firstName}</dd>
              <dd>Last Name: {lastName}</dd>
            </dl>
            <div className={pms.signout} onClick={handleSignOut}>
              Sign Out
            </div>
          </div>
        </div>
        <img
          className={pms.close}
          src={icClose}
          alt="close menu"
          onClick={props.close}
        />
      </>
    </Mask>
  );
}

export default Profile