import React, { MouseEventHandler, useContext } from 'react';
import * as pms from './profile.module.scss';
import { getAccount, deleteAccount } from '@service/AccountService';
import { LayoutContext } from '@components/Context';

interface IProps {
  visible: boolean
  close: MouseEventHandler<HTMLElement>
}

const Profile = (props: IProps) => {
  const account = getAccount();
  const { firstName, lastName, email } = account || {};
  const { visible, close } = props;
  const { collectionProductsLength, orderNum } = useContext<any>(LayoutContext);
  function handleSignOut(e: any) {
    deleteAccount();
    close && close(e);
  }

  if(!visible) return null;
  return (
    <div className={`${pms.lMenu} ${visible ? pms.lMenuMShow : ""}`}>
      <div className={pms.mask} onClick={close}></div>
      <div className={pms.menuContainer}>
        <dl>
          {orderNum > 0 ? <a href="/order" className={pms.order} onClick={close}>
            <dt>My orders</dt>
            <span>{orderNum}</span>
          </a> : null}
          <dt>B-LOVED List</dt>
          <dd>
            <a href="/collection" className={`${pms.arrow}`} onClick={close}>
              <span>B-LOVED Products</span>
              <span className={`${pms.lovedCount}`}>
                {collectionProductsLength} items
              </span>
            </a>
          </dd>
          <dt>My Account</dt>
          <dd>{email}</dd>
          <dd>First Name: {firstName}</dd>
          <dd>Last Name: {lastName}</dd>
        </dl>
        <div className={pms.signout} onClick={handleSignOut}>
          Sign Out
        </div>
      </div>
    </div>
  );
}

export default Profile