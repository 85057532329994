import React, { MouseEventHandler } from 'react';
import * as ckms from './cookie.module.scss';
import Close from '@images/icon_close.png';

interface IProps {
  close: MouseEventHandler<HTMLDivElement>
}

const Cookie = (props: IProps) => {
  return (
    <div className={ckms.mCookie}>
      <div className={ckms.mCookieMain}>
        We use cookies for operational, analytic, and marketing purposes, including to improve your site experience. By interacting with our website, you agree to our use of cookies (described in our <a href="/privacy/"> Privacy Policy </a>).
      </div>
      <img className={ckms.close} src={Close} onClick={props.close} alt="close icon" />
      <div className={ckms.got} onClick={props.close}>Got It!</div>
    </div>
  )
}

export default Cookie