import { useState, useRef, useEffect, RefObject } from "react";
import { isBrowser } from "@/lib/utils";
const MOBILE_SCREEN_WIDTH = 960;

export default function useStickyHeader(isMobile = false, elRef?: RefObject<HTMLElement>) {
  const [fixed, setFixed] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const headerRef = useRef<HTMLElement>(null);
  const headerOffsetHeight = useRef(0);
  const getScrollY = () => window.scrollY || document.documentElement.scrollTop;

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    const getHeaderHeight = () => {
      const headerEl = (elRef?.current ||  headerRef.current) as HTMLElement;
      headerOffsetHeight.current =
        (headerEl?.getBoundingClientRect
          ? headerEl?.getBoundingClientRect()?.height
          : headerEl?.offsetHeight) || 0;
      setIsMobileScreen(
        window.innerWidth < MOBILE_SCREEN_WIDTH && window.innerWidth > 0
      );
    };
    getHeaderHeight();
    window.addEventListener("resize", getHeaderHeight);
    return () => {
      window.removeEventListener("resize", getHeaderHeight);
    };
  }, []);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    let prevPosiotion = getScrollY();
    const onScroll = () => {
      const scrollY = getScrollY();
      if (scrollY > prevPosiotion) {
        setFixed(false);
      } else {
        if (scrollY > headerOffsetHeight.current) {
          setFixed(true);
        } else if (scrollY <= 0) {
          setFixed(false);
        }
      }
      prevPosiotion = scrollY;
    };
    if ((isMobileScreen && isMobile) || (!isMobile && !isMobileScreen)) {
      window.addEventListener("scroll", onScroll, { passive: true });
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [isMobileScreen, isMobile]);

  return [fixed, headerRef] as const;
}