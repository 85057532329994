import React from 'react';
import './index.scss';

function SearchPanel(props: any) {
  const { items, title, onClickItem, left, top, zIndex } = props || {}
  return (
    <div className='spanel' style={{ left: left, top: top, zIndex: zIndex }}>
      <div className='spanel-title'>{title}</div>
      <div className='spanel-items'>
        {
          items?.map((item: any, index: number) => {
            return (
              <div key={'spanel_' + item + index} className='spanel-item' onMouseDown={(e: any) => onClickItem(e, item)}>
                {item}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default SearchPanel
