import querystring from 'query-string';
import { navigate } from 'gatsby';

export const isBrowser = typeof window !== 'undefined'

export let type = (obj: any) => {
    return {}.toString.call(obj).replace(/^\[object (.+)\]$/, '$1').toLowerCase()
}

// 判断浏览器函数
export function isMobile() {
    if (!isBrowser) {
        return false
    }
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 移动端
    } else {
        return false; // PC端
    }
}

export function parseUrl(url: string) {
    try {
        const data = querystring.parseUrl(url, { parseFragmentIdentifier: true });
        if (data && data.fragmentIdentifier && data.fragmentIdentifier.indexOf('?') >= 0) {
            const endParams = querystring.parseUrl(data.fragmentIdentifier).query;
            Object.assign(data.query, endParams);
        }
        return data;
    } catch (e) {
        console.error(e);
    }
    return {};
}

/**
 * decode url query params
 */
export function parseUrlParams(url: string) {
    var res: any = parseUrl(url);
    if (res !== void 0 && res.query !== void 0) {
        return res.query;
    }
    return {};
}

export function stringifyParams(params: any, options: any = { skipEmptyString: true, skipNull: true }) {
    if (!params) return ''
    try {
        return querystring.stringify(params, options)
    } catch (e) {}
}

// todo handle query params
export function navigateTo(url: string | null) {
    try {
        if (!url) return
        if (url.indexOf('bieyangstyle://plp') >= 0) {
            const q = url?.split('bieyangstyle://plp')?.[1];
            navigate('/searchList/' + q);
        } else if (url.indexOf('http') < 0) {
            // path directly
            navigate(url);
        } else if (url.indexOf('beyondstyle.us/sweepstake/game/index.html') > -1) {
            window.open(url);
        } else if (url.startsWith('https://www.beyondstyle.us')) {
            const path = url.split('https://www.beyondstyle.us')[1]
            navigate(path);
        } else {
            let timer = setTimeout(() => {
                clearTimeout(timer)
                window.open(url);
            }, 10)
        }
    } catch(e) {
        console.warn(e)
    }
}

// todo handle query params
export function getUrl(url: string | undefined | null) {
    if (!url) {
        return ''
    }
    if (url.indexOf('bieyangstyle://plp') >= 0) {
        const q = url?.split('bieyangstyle://plp')?.[1]
        return '/searchList/' + q
    } else {
        return url
    }
}

// deprecated
// export function getProductUrl(id: string) {
//     return `https://www.beyondstyle.us/product/${id}/`
// }

// without extension
export function getImageFilename(url: string | undefined | null): string {
    if (!url) {
        return ""
    }
    const ret = /\/([^\/]+)\.(?:png|webp|jpg|jpeg|gif|avif|ico)$/g.exec(url)
    if (ret?.length && ret.length > 1) {
        return ret[1]
    }
    return ""
}

export function getImageUrl(url: string | undefined | null, styleName?: string): string {
    if (!url) {
        return ""
    }
    if (url.startsWith("http://")) {
        url = url.replace("http://", "https://")
    }
    if (/(bybieyang\.com)\/.*\.(png|jpg|jpeg)$/i.test(url)) {
        url = url.replace('5thave-img-cdn.bybieyang.com', '5thave-img-cdn.beyondstyle.us')
        url = url.replace('5thave-img-cdn-g.bybieyang.com', '5thave-img-cdn.beyondstyle.us')
    }
    // if image url has any query params, assume that the image will override the oss params 
    if (/(beyondstyle\.us|bybieyang\.com|bieyangapp\.com)\/.*\.(png|jpg|jpeg)$/i.test(url)) {
        // by default s1 -> webp + 90q
        return url + (styleName !== 'null' ? `?x-oss-process=style/${styleName || "s1"}` : '')
    }
    return url
}

export function getImage(image: any | undefined, priority = 'low', style = '') {
    if (!image) return ''
    if (!image.full?.url && !image.thumbnail?.url) return ''
    if (!image.thumbnail?.url) {
        return getImageUrl(image.full?.url, style)
    } else if (priority == 'full' && !!image.full?.url) {
        return getImageUrl(image.full?.url, style)
    }
    return getImageUrl(image.thumbnail.url, style)
}

export function getFirstImageUrl(images: any[] | undefined, priority = 'low', style = '') {
    if (!images?.length) return ''
    return getImage(images?.[0], priority, style)
}

export function getSrcSet(images: any[] | undefined) {
    try {
        if (!images?.length) return undefined
        const firstImage = images?.[0]
        if (!firstImage) return undefined
        if (!firstImage.full?.url || !firstImage.thumbnail?.url) return undefined
        if (!firstImage.full?.width || !firstImage.thumbnail?.width) return undefined
        return `${getImageUrl(firstImage.thumbnail.url)} ${firstImage.thumbnail.width}w, ${getImageUrl(firstImage.full.url)} ${firstImage.full.width}w`
    } catch (e) {
        return undefined
    }
}

const accentMap: any = {
    'a': /[àáâäãåæ]/g,
    'c': /[ç]/g,
    'e': /[èéêëē]/g,
    'i': /[ìíîï]/g,
    'd': /[ð]/g,
    'n': /[ñ]/g,
    'o': /[òóôöõøœ]/g,
    'u': /[ùúûü]/g,
    'y': /[ýÿ]/g,
    'A': /[ÀÁÂÄÃÅÆ]/g,
    'C': /[Ç]/g,
    'E': /[ÈÉÊËĒЁ]/g,
    'I': /[ÌÍÎÏ]/g,
    'D': /[Ð]/g,
    'N': /[Ñ]/g,
    'O': /[ÒÓÔÖÕØŒ]/g,
    'U': /[ÙÚÛÜŪ]/g,
    'Y': /[ÝŸ]/g
  }

function replaceSpecialCharacters(text: string) {
    for (const char in accentMap) {
        if (accentMap.hasOwnProperty(char)) {
            text = text.replace(accentMap[char], char)
        }
    }
    return text
}

export function normalizeTerm(name: string | null | undefined, options: any = {}): string {
    const { keepCase, keepDot } = options || {}
    if (!name) {
        return ""
    }
    try {
        name = name.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
        name = name.replace(/[`~!®@#$%^&*()_|+\-=?;:'’",<>\{\}\[\]\\\/™φ°•·]/gi, '')
        if (!keepDot) {
            name = name.replace(/[.]/gi, '')
        }
        name = replaceSpecialCharacters(name)
        name = name.replace(/[\s\t\n]+/gi, ' ')
        name = name.trim()
        name = name.replace(/[\s\t\n]/gi, '-')
    } catch(e) {

    }
    return encodeURIComponent(keepCase ? name : name.toLowerCase())
}

export function getNormalizePath(v: string | undefined | null): string {
    if (!v) return ""
    const path = normalizeTerm(v)
    return `${path}`
}

// brand + name 
export function getProductName(name: string | null | undefined, brand: string | null | undefined): string {
    if (!name) {
        return ""
    }
    const n = normalizeTerm(name)
    const b = normalizeTerm(brand)
    if (!!b) {
        if (n.indexOf(b) === -1) {
            return b + "-" + n
        }
    }
    return n
}

export function getProductUrlV2(id?: string, name?: string | null | undefined, brand?: string | null | undefined) {
    if (!id) {
        return ''
    }
    const namePath = getProductName(name, brand)
    return `/prod/${namePath}?id=${id}`
}

export function getProductUrlWithVariants(id: string, name: string | null | undefined, brand: string | null | undefined, color?: string, size?: string) {
    if (!id) {
        return ''
    }
    const namePath = getProductName(name, brand)
    const params = stringifyParams({
        id, 
        color: !!color ? sanitize(color) : undefined, 
        size: !!size ? sanitize(size): undefined,
    })
    return `/prod/${namePath}?${params}`
}


export function isNoFollowLink(link: string | null) {
    if (!link) return false
    if (/(beyondstyle\.us|bybieyang\.com|bieyangapp\.com).*/i.test(link)) {
        // by default s1 -> webp + 90q
        return false
    }
    return true
}

// with slash at the beginning
export function isActivePath(path: string | null): boolean {
    if (!isBrowser) return false
    if (!path) return false
    if (path === '/') {
        return window.location.pathname === path
    }
    return window.location.pathname.startsWith(path)
}

export function isSearchPath() {
    return isActivePath('/searchList/')
}

export function centsToDollars(cents: number) {
    const dollars = cents / 100
    return dollars.toFixed(2).replace(/\.?0*$/, '')
}

export function addCentsToPriceTag(price: string, cents: number): string {
    try {
        let dollars = Number(price.replace(/[^\d.-]/g, ''))
        dollars = (dollars * 100 + cents) / 100
        return '$' + dollars.toFixed(2).replace(/\.?0*$/, '')
    } catch (e) {}
    return price
}

export function formatPriceTag(priceTag: string | undefined) {
    if (!priceTag) return ''
    // Remove currency symbol and whitespace
    try {
        return priceTag.replace(/\$|\s|起/g, '')
    } catch (e) {
    }
    return priceTag
}

export function priceTagToCents(priceTag: string | undefined) {
    if (!priceTag) return 0
    // Remove currency symbol and whitespace
    try {
        let cleaned = priceTag.replace(/\$|\s|起/g, '')
        return Math.round((+cleaned) * 100)
    } catch (e) {
    }
    return 0
}

// https://pinterest.com/pin/create/button/?url=&media=
export function shareToPinterest(url: string, image: string, description: string) {
    if (!isBrowser) return
    if (!url) return
    window.open(`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(image)}&description=${encodeURIComponent(description)}`, '_blank')
}

export function shareToFacebook(url: string) {
    if (!isBrowser) return
    if (!url) return
    window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}&s=p`, '_blank')
}

// https://twitter.com/intent/tweet?url=
// https://twitter.com/share?url=
export function shareToTwitter(url: string) {
    if (!isBrowser) return
    if (!url) return
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&hashtags=beyondstyle`, '_blank')
}

export function shareToLinkedIn(url: string) {
    if (!isBrowser) return
    if (!url) return
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`, '_blank')
}

// https://www.reddit.com/submit?url=http%3A%2F%2Fgithub.com&title=GitHub
// whatsapp://send
// https://web.whatsapp.com/send?text= 
export function shareToWhatsapp(url: string) {
    if (!isBrowser) return
    if (!url) return
    window.open(`https://web.whatsapp.com/send?text=${encodeURIComponent(url)}`, '_blank')
}

export function isEqual(obj1: any, obj2: any) {
    if (obj1 === obj2) { // it's just the same object. No need to compare.
        return true;
    }
   
    if (typeof obj1 === "object" && obj1 != null && (typeof obj2 === "object" && obj2 != null)) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length)
            return false; // different number of keys, they are not the same.
      
        for (let prop in obj1) {
            if (obj2.hasOwnProperty(prop))
            { // does the second object have a matching property?
                if (! isEqual(obj1[prop], obj2[prop])) // if they are not the same, these objects are not equal.
                    return false;
            }
            else
                return false; // the second object doesn't have this prop, these objects are not equal.
        }
        return true; // all properties are equal.
    }
    else if (obj1 !== obj2)
        return false; // the simple comparison
    return true;
}

export function getArticleId(url: string) {
    const regex = /\/([^\/]+)\/index\.html$/
    const match = url.match(regex)
    if (match) {
      return match[1]
    } else {
      return null
    }
}

export function fixNumber(number: number, decimal = 2) {
    return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function sanitize(value?: string) {
    if (!value) return ''
    return value
        .replace(/[\'\"\(\)\[\]#°=!?，。’”©™″<>%⤢【】✔…」`]/g, '')
        .replace(/[\s\t\n\/\\&+:↕|*;×⟷,]/g, '-')
        .replace(/[-]+/g, '-')
}

// note: it's weird that gatsby seems not porperly handle url-encoded \' character 
export function isMatchedValue(value1?: string | null, value2?: string) {
    if (!value1 && !value2) return true
    if (!value1 || !value2) return false
    return sanitize(value1) == sanitize(value2)
}

export function capitalizeFirstLetter(string ?: string) {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const BOTS = [
    'googlebot',
    'adsbot-google',
    'baiduspider',
    'gurujibot',
    'yandexbot',
    'msnbot',
    'bingbot',
    'facebookexternalhit',
    'linkedinbot',
    'twitterbot',
    'slackbot',
    'telegrambot',
    'applebot',
    'pingdom',
    'tumblr ',
    'pinterestbot',
    'semrushbot',
    'ahrefsbot',
    'bytespider',
    'amazonbot',
    'petalbot',
    'dotbot',
]

export function isBot(userAgent?: string) {
    if (!userAgent) return false
    const IS_BOT_REGEXP = new RegExp('^.*(' + BOTS.join('|') + ').*$')
    if (IS_BOT_REGEXP.test(userAgent)) {
        return true
    }
    return false
}

export const copyToClipboard = (value: string) => {
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";
  const el = document.createElement("textarea");
  el.style.fontSize = "12pt";
  el.style.border = "0";
  el.style.padding = "0";
  el.style.margin = "0";
  el.style.position = "absolute";
  el.style[isRTL ? "right" : "left"] = "-9999px";
  let yPosition = window.pageYOffset || document.documentElement.scrollTop;
  el.style.top = `${yPosition}px`;
  el.setAttribute("readonly", "");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  el.remove();
};

export const isAndroid = () => isBrowser && /Android/i.test(window.navigator.userAgent);

export const isIOS = () =>
  isBrowser && /iP(hone|ad|od)|Macintosh/i.test(window.navigator.userAgent);

export function isSameDay(targetTime: Date, currentDate: Date) {
    return (
      targetTime.getFullYear() === currentDate.getFullYear() &&
      targetTime.getMonth() === currentDate.getMonth() &&
      targetTime.getDate() === currentDate.getDate()
  );
}