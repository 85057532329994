import React, { MouseEventHandler, useState } from 'react';
import './searchBox.scss';
import SearchInput from '@modules/search/searchInput';

interface IProps {
  q?: string
  placeholder?: string
  click?: MouseEventHandler<HTMLDivElement>
}

const SearchBox = (props?: IProps) => {
  const { q } = props || {}
  const [visible, setVisible] = useState(false)
  const onClick = (e: any) => {
    e.preventDefault()
    setVisible(true)
  }
  return (
    <>
      <div className='m-search' onClick={onClick}>
        <a
          href={q ? `/search/?q=${q}` : '/search/'}
          className={q ? 'm-search-box value-style' : 'm-search-box'}>
          {q || `Search for any product or brand`}
        </a>
      </div>
      <SearchInput q={q} visible={visible} setVisible={setVisible} />
    </>
  )
}

export default SearchBox