import axios from 'axios';
import { getAccount } from './sessionManager';
import { refreshAccount } from './AccountService';
import { createHttpAgent, createHttpsAgent } from './agent';

const dapperClient = axios.create({
  baseURL: 'https://dapper.beyondstyle.us',
  httpAgent: createHttpAgent(),
  httpsAgent: createHttpsAgent(),
  headers: {
    "X-BXL-User-Agent": 'BeyondStyle H5/1.0.0',
  }
})

dapperClient.interceptors.request.use(
  function (config: any) {
    // 在发送请求之前做些什么
    const token = getAccount()?.access_token
    if(token && !config?.headers?.Authorization) {
        config.headers["Authorization"] = `Bearer ${token}`
    }
    return config
  },
  function (error: any) {
    return Promise.reject(error)
  }
)

dapperClient.interceptors.response.use(
  function (response: any): any {
    return response.data
  },
  function (error: any): any {
    const code = error && error.response && error.response.status
    if (code === 401) {
      const token = getAccount()?.access_token
      if(token && !error.config.__isRetryRequest) {
        // 登录过期
        return new Promise((resolve, reject) => {
          refreshAccount()
            .then((res: any) => {
              error.config.__isRetryRequest = true
              error.config.headers.Authorization = 'Bearer ' + res?.access_token
              axios(error.config).then((res) => resolve(res?.data), (error) => reject((error?.response && error?.response?.data) || error))
            }).catch((err: any) => {
              // refresh_token 过期
              error.config.__isRetryRequest = true
              error.config.headers.Authorization = ''
              axios(error.config).then((res) => resolve(res?.data), (error) => reject((error?.response && error?.response?.data) || error))
            })
        })
      } else {
        // 没有权限
        if (!!window) {
          window.location.href = '/login'
        }
      }
    }
    return Promise.reject((error.response && error.response.data) || error)
  }
)

export default dapperClient
