import { createContext } from 'react'

export const LayoutContext = createContext({});

export const LayoutProvider = LayoutContext.Provider;

export const CategoryContext = createContext({});

export const CategoryProvider = CategoryContext.Provider;

export const ProductContext = createContext({})

export const ProductProvider = ProductContext.Provider

export const SearchContext = createContext({})

export const SearchInfoProvider = SearchContext.Provider