import React, { useEffect, useState, ReactElement, useCallback } from 'react';
import { LayoutProvider, SearchInfoProvider } from '@components/Context';
import './layout.scss';
import { getCollectionProductList, fetchOrderNum } from '@service/api';
import { categoryTree } from '@lib/categoryUtils';
import Cookie from './Cookie';
import { isBrowser, parseUrlParams, isSearchPath } from '@lib/utils';
import Header from './Header';
import HeaderMobile from './HeaderM';
import Footer from './FooterM';
import type { CollectionResponse } from '@service/api';
import { trackEvent, pinVisit, setGAIdentity, impactIdentify, getProfileId } from '@lib/analytics';
import { getAccount, isLogin } from '@service/AccountService';
import sessionStorage from "@lib/sessionStorage";
import queryString from 'query-string';

export interface ILayoutContext {
  pageName: string;
  categories: Object;
  email: string;
  orderNum: string | number,
  updateEmail: (email: string) => void;
  updateCollectionProducts: (
    products: (pre: CollectionResponse["list"]) => CollectionResponse["list"]
  ) => void;
  collectionProducts: CollectionResponse["list"];
  collectionProductsLength: number;
  updateCollectionProductsLength: (count: number) => void;
  handleUpdateCollection: () => void
  handleUpdateOrderNum: () => void
}

interface ILayout {
  children: ReactElement
  pageContext: any
  serverData?: any
}

const Layout = ({ children, pageContext, serverData }: ILayout) => {
  const [query, setQuery] = useState(() => {
    if (!isBrowser) {
      return ''
    }
    if (!isSearchPath()) {
      return ''
    }
    try {
      const params = parseUrlParams(window.location.href)
      return params?.q?.trim() || ''
    } catch (e) {}
    return ''
  })
  const updateQuery = useCallback((q?: string) => {
    if (isSearchPath()) {
      setQuery(q || '')
    } else {
      // navigate to search page
      window.location.href = `/searchList/?q=${encodeURIComponent(q || '')}`
    }
  }, [])
  const [showCookie, setShowCookie] = useState(() => {
    if (!isBrowser) return false
    try {
      const isShowCookie = localStorage?.getItem('USE_COOKIE')
      if (isShowCookie === undefined) return true
      return isShowCookie != 'true'
    } catch(e) {
      return false
    }
  })

  const updateEmail = useCallback((email: string) => {
    updateStore((store) => {
      return { ...store, email }
    })
  }, [])

  const updateCollectionProducts = useCallback((
    collectionProducts: (
      pre: CollectionResponse["list"]
    ) => CollectionResponse["list"]
  ) => {
    if (!collectionProducts) return
    updateStore((store) => {
      return {
        ...store,
        collectionProducts: collectionProducts?.(store.collectionProducts),
      }
    })
  }, [])

  const updateCollectionProductsLength = useCallback((count: number) => {
    updateStore((store) => {
      return { ...store, collectionProductsLength: count }
    })
  }, [])

  const handleUpdateOrderNum = useCallback(() => {
    fetchOrderNum().then((res: any) => {
        updateStore((store) => {
            return { ...store, orderNum: res.count || 0 }
        })
    }).catch(() => {})
  }, [])

  const handleUpdateCollection = useCallback(() => {
    getCollectionProductList({
      from: 0,
      to: 1,
      sort: "CREATE_TIME",
      asc: true,
    }).then((res: any) => {
      updateCollectionProductsLength(res.total);
    }).catch(() => { })
  }, [])

  const [store, updateStore] = useState<ILayoutContext>({
    pageName: pageContext.pageName || "",
    categories: categoryTree,
    email: "",
    orderNum: 0,
    updateEmail: updateEmail,
    collectionProducts: [],
    updateCollectionProducts,
    collectionProductsLength: 0,
    updateCollectionProductsLength,
    handleUpdateCollection,
    handleUpdateOrderNum
  })

  useEffect(() => {
    if (isBrowser) {
      const profileId = getProfileId()
      let userId = undefined
      if (isLogin()) {
        handleUpdateCollection()
        handleUpdateOrderNum()
        userId = getAccount()?.id
        setGAIdentity(userId)
      }
      impactIdentify(userId, profileId)
    }
  }, [])

  const onCloseCookie = useCallback(() => {
    setShowCookie(false)
    try {
      localStorage?.setItem('USE_COOKIE', "true")
    } catch(e) {}
  }, [])

  useEffect(() => {
    if(isBrowser) {
      try {
        const utmMediumKey = "utm_medium";
        const utmContentKey = "utm_content";
        const { utm_medium, utm_content } = queryString.parseUrl(
          window.location.href
        ).query;
        if (utm_medium && utm_medium !== sessionStorage.getItem(utmMediumKey)) {
          trackEvent("web_page_open", {
            event_category: "subscribe",
            event_value: window.location.href,
            event_medium: utm_medium,
            event_content: utm_content,
          })
          sessionStorage.setItem(utmMediumKey, utm_medium as string)
          utm_content && sessionStorage.setItem(utmContentKey, utm_content as string);
        }
      } catch (e) {

      }

      if (store.pageName !== 'PRODUCT') {
        pinVisit()
      }
    }
  }, [])

  return (
    <LayoutProvider value={store}>
      <div className={`page-c ${store.pageName}`}>
        <SearchInfoProvider value={{ query, updateQuery }}>
          <Header pageName={store.pageName} query={query} updateQuery={updateQuery} />
          <HeaderMobile pageName={store.pageName} />
          {children}
        </SearchInfoProvider>
        {(pageContext?.footer || serverData?.footer) ? <Footer pageName={store.pageName} /> : null}
        {
          showCookie ? <Cookie close={onCloseCookie} /> : null
        }
      </div>
    </LayoutProvider>
  )
}

export default Layout
