import React from 'react';
import * as Style from './department.module.scss';
import { HOME_DEPARTMENT_PRE_LOVED } from '@lib/constants';
import departmentBundle from '@lib/homeCategories.json';
import { normalizeTerm } from '@lib/utils';

const [WOMEN, MEN, BEAUTY, OUTDOOR, BABY] = departmentBundle.children

const Department = () => {
  // const layoutContext = useContext(LayoutContext)
  const renderTerm = (list) => {
    const getLink = (category) => {
      const { id, query, path, term } = category
      let _path = ''
      if (path) {
        _path = path
      } else if (!!id) {
        _path = term
      }
      return !!query ? `/searchList/?${query}` : `/category/${normalizeTerm(_path)}${_path ? '/' : ''}`
    }
    return list?.map((cate, index) => {
      return (
        <div className={cate.children?.length ? Style.cateItem : Style.text} key={index + '_out2_' + cate.category.id}>
          <a href={getLink(cate.category)} aria-label={cate.category?.term}>
            <div className={cate.children?.length ? Style.title4 : Style.text}>
              {cate.category.term}
            </div>
          </a>
          {
            cate.children?.map((c, i) => {
              return (
                <a href={getLink(c.category)} key={`${index}_${i}__inner_${c.category.id}`} aria-label={c.category?.term}>
                  <div className={Style.text}>
                    {c?.category?.term}
                  </div>
                </a>
              )
            })
          }
        </div>
      )
    })
  }

  return (
    <div
      className={Style.pDepartment} 
      onMouseMove={(e) => { 
        e.preventDefault() 
        e.stopPropagation()
      }}
    >
      {
        [WOMEN, MEN].map((category, index) => {
          return (
            <div className={Style.catePanel} key={index + '_out_' + category.category.id}>
              <div className={Style.title3}>{category.category.term}</div>
              <div className={Style.cateChild}>
                <div className={Style.top2}>
                  <div>
                    {renderTerm(category.children.slice(0, 2))}
                  </div>
                  <div>
                    {renderTerm(category.children.slice(2))}
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      <div className={Style.threeColumn}>
        {
          [OUTDOOR, BEAUTY].map((category, index) => {
            return (
              <div className={Style.catePanel} key={index + '_out_' + category.category.id}>
                <div className={Style.title3}>{category.category.term}</div>
                <div>
                  <div className={Style.top2}>
                    {renderTerm(category.children)}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className={Style.fourColumn}>
        {
          [BABY, HOME_DEPARTMENT_PRE_LOVED].map((category, index) => {
            return (
              <div className={Style.catePanel} key={index + '_out_' + category.category.id}>
                <div className={Style.title3}>{category.category.term}</div>
                <div>
                  <div className={Style.top2}>
                    {renderTerm(category.children)}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Department
