import React from 'react';

class ErrorBoundary extends React.Component<any> {
  componentDidCatch (error: any, info: any) {
    if (!!error) {
      console.error(error, info ? info.componentStack : '')
    }
  }
  render () {
    const { children } = this.props
    return children
  }
}

export default ErrorBoundary
